/* load setting */
window.addEventListener('load', function() {
  document.body.setAttribute('data-is-loaded', 'true')
  const kvTitles = document.querySelectorAll('.p-top-kv__title')
  const kvRead = document.querySelector('.p-top-kv__read')
  if (kvTitles.length === 2 && kvRead) {
    setTimeout(() => {
      Array.prototype.slice.call(kvTitles).forEach(kvTitle => {
        kvTitle.classList.add('-active')
      })
    }, 700)
    setTimeout(() => kvRead.classList.add('-active'), 2200)
  }
})

window.addEventListener('load', function() {
  document.body.setAttribute('data-is-loaded', 'true')
  const kvTitles = document.querySelectorAll('.c-top-ttl__title')
  if (kvTitles.length === 4) {
    setTimeout(() => {
      Array.prototype.slice.call(kvTitles).forEach(kvTitle => {
        kvTitle.classList.add('-active')
      })
    }, 700)
  }
})
