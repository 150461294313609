import jump from 'jump.js'

const handleClick = ({
  hash,
  isHeaderOffset = false,
  isHeaderOffsetPc = false,
  isHeaderOffsetSp = false,
  isAnim = true,
}: {
  hash: string
  isHeaderOffset?: boolean
  isHeaderOffsetPc?: boolean
  isHeaderOffsetSp?: boolean
  isAnim?: boolean
}): void => {
  const $elm: HTMLElement | null = document.querySelector(hash)
  const $header: HTMLElement | null = document.querySelector('.l-header')
  if ($elm && $header) {
    const { clientHeight } = $header
    const offset = (() => {
      if (
        isHeaderOffset ||
        (isHeaderOffsetPc && matchMedia('(min-width: 768px)').matches) ||
        (isHeaderOffsetSp && matchMedia('(max-width: 767.98px)').matches)
      ) {
        return -clientHeight
      }
    })()
    jump(hash, {
      duration: isAnim ? 500 : 0,
      offset,
    })
  }
}

/* common */
window.addEventListener('load', () => {
  const $anchorNodes: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
    'a[href^="#"]'
  )
  const $anchors: Array<HTMLAnchorElement> = Array.prototype.slice.call(
    $anchorNodes,
    0
  )
  const hash: string = location.hash

  if (hash.indexOf('#') !== -1) {
    handleClick({ hash })
  }

  $anchors.forEach(anchor => {
    const { hash, dataset } = anchor
    const { headerOffset, headerOffsetPc, headerOffsetSp } = dataset
    if (hash === '#') return

    anchor.addEventListener('click', e => {
      e.preventDefault()
      handleClick({
        hash,
        isHeaderOffset: typeof headerOffset !== 'undefined',
        isHeaderOffsetPc: typeof headerOffsetPc !== 'undefined',
        isHeaderOffsetSp: typeof headerOffsetSp !== 'undefined',
      })
    })
  })
})
