import ScrollMagic from 'scrollmagic'
import stores from './stores/index'

/* pageup */
document.addEventListener('DOMContentLoaded', () => {
  const pageupController = new ScrollMagic.Controller()
  new ScrollMagic.Scene({
    triggerElement: 'body',
    triggerHook: 0,
    offset: 200,
  })
    .on('enter', stores.pageup.show)
    .on('leave', stores.pageup.hide)
    .addTo(pageupController)

  new ScrollMagic.Scene({
    triggerElement: '.l-footer',
    triggerHook: 1,
  })
    .setClassToggle('#js-pageup', '-static')
    .addTo(pageupController)
})

/* top */
document.addEventListener('DOMContentLoaded', () => {
  const topController = new ScrollMagic.Controller()

  new ScrollMagic.Scene({
    triggerElement: '#Message',
    triggerHook: 0.7,
  })
    .on('enter', () => {
      const $h2 = document.querySelector('#MessageH2')
      const $h3 = document.querySelector('#MessageH3-1')
      const $body = document.querySelector('#MessageBody-1')
      if (!($h2 && $h3 && $body)) return
      $h2.classList.add('-active')
      setTimeout(() => $h3.classList.add('-active'), 750)
      setTimeout(() => $body.classList.add('-active'), 2150)
    })
    .addTo(topController)

  new ScrollMagic.Scene({
    triggerElement: '#CarouselBand',
    triggerHook: 0.7,
  })
    .on('enter', () => {
      const $carouselBandText = document.querySelector('#CarouselBandText')
      const $h3 = document.querySelector('#MessageH3-2')
      const $body = document.querySelector('#MessageBody-2')
      if (!($carouselBandText && $h3 && $body)) return
      $carouselBandText.classList.add('-active')
      setTimeout(() => $h3.classList.add('-active'), 750)
      setTimeout(() => $body.classList.add('-active'), 2150)
    })
    .addTo(topController)

  new ScrollMagic.Scene({
    triggerElement: '#CarouselBand-02',
    triggerHook: 0.7,
  })
    .on('enter', () => {
      const $carouselBandText = document.querySelector('#CarouselBandText-02')
      const $h3 = document.querySelector('#MessageH3-2')
      const $body = document.querySelector('#MessageBody-2')
      if (!($carouselBandText && $h3 && $body)) return
      $carouselBandText.classList.add('-active')
      setTimeout(() => $h3.classList.add('-active'), 750)
      setTimeout(() => $body.classList.add('-active'), 2150)
    })
    .addTo(topController)

  new ScrollMagic.Scene({
    triggerElement: '#CarouselBand-03',
    triggerHook: 0.7,
  })
    .on('enter', () => {
      const $carouselBandText = document.querySelector('#CarouselBandText-03')
      const $h3 = document.querySelector('#MessageH3-2')
      const $body = document.querySelector('#MessageBody-2')
      if (!($carouselBandText && $h3 && $body)) return
      $carouselBandText.classList.add('-active')
      setTimeout(() => $h3.classList.add('-active'), 750)
      setTimeout(() => $body.classList.add('-active'), 2150)
    })
    .addTo(topController)

  new ScrollMagic.Scene({
    triggerElement: '#CarouselBand-04',
    triggerHook: 0.7,
  })
    .on('enter', () => {
      const $carouselBandText = document.querySelector('#CarouselBandText-04')
      const $h3 = document.querySelector('#MessageH3-2')
      const $body = document.querySelector('#MessageBody-2')
      if (!($carouselBandText && $h3 && $body)) return
      $carouselBandText.classList.add('-active')
      setTimeout(() => $h3.classList.add('-active'), 750)
      setTimeout(() => $body.classList.add('-active'), 2150)
    })
    .addTo(topController)

  new ScrollMagic.Scene({
    triggerElement: '#Message02',
    triggerHook: 0.7,
  })
    .on('enter', () => {
      const $h2 = document.querySelector('#MessageH2')
      const $h3 = document.querySelector('#MessageH3-3')
      const $body = document.querySelector('#MessageBody-3')
      if (!($h2 && $h3 && $body)) return
      $h2.classList.add('-active')
      setTimeout(() => $h3.classList.add('-active'), 750)
      setTimeout(() => $body.classList.add('-active'), 2150)
    })
    .addTo(topController)

  new ScrollMagic.Scene({
    triggerElement: '#Message03',
    triggerHook: 0.7,
  })
    .on('enter', () => {
      const $h2 = document.querySelector('#MessageH2')
      const $h3 = document.querySelector('#MessageH3-4')
      const $body = document.querySelector('#MessageBody-4')
      if (!($h2 && $h3 && $body)) return
      $h2.classList.add('-active')
      setTimeout(() => $h3.classList.add('-active'), 750)
      setTimeout(() => $body.classList.add('-active'), 2150)
    })
    .addTo(topController)
})
