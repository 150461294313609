import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'

window.addEventListener('load', function() {
  new Swiper('.js-top-carousel', {
    slidesPerView: 3,
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 3000,
    },
  })
})
