import stores from './stores/index'

/* drawer */
document.addEventListener('DOMContentLoaded', () => {
  const $hamburger = document.querySelector('#js-header-hamburger-btn')
  if (!$hamburger) return
  $hamburger.addEventListener('click', e => {
    e.preventDefault()
    stores.drawer.toggle()
  })
  const $overlay = document.querySelector('#js-overlay')
  if (!$overlay) return
  $overlay.addEventListener('click', e => {
    e.preventDefault()
    stores.drawer.hide()
  })
})
