import VisibleStore from './VisibleStore'

const drawer = new VisibleStore('data-is-visible-drawer')

const pageup = new VisibleStore('data-is-visible-pageup')

const stores = {
  drawer,
  pageup,
}

export default stores
