interface State {
  isVisible: boolean
}

export default class VisibleStore {
  private _state: State = {
    isVisible: false,
  }

  constructor(private _datasetKey: string) {}

  get state() {
    return this._state
  }

  get datasetKey() {
    return this._datasetKey
  }

  private _update = (): void => {
    const $body = document.body
    const { isVisible } = this.state
    if ($body) {
      if (isVisible) {
        $body.setAttribute(this.datasetKey, 'true')
      } else {
        $body.setAttribute(this.datasetKey, 'false')
      }
    }
  }

  public show = (): void => {
    this.state.isVisible = true
    this._update()
  }

  public hide = (): void => {
    this.state.isVisible = false
    this._update()
  }

  public toggle = (): void => {
    const { isVisible } = this.state
    this.state.isVisible = !isVisible
    this._update()
  }
}
